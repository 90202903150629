
import React, { memo } from 'react';
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import SOCIALS from '@utils/constants/social'
import useCommonStyles from '@styles/use-styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 0, 8)
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.custom.palette.blue,
    margin: theme.spacing(2, 0)
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0)
  },
  description: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0)
  },
  link: {
    fontSize: 16,
    fontWeight: 'lighter',
    color: `${theme.palette.primary.main} !important`
  },
  list: {
    fontSize: 16,
    maxWidth: 650,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0),
    '& li': {
      marginBottom: theme.spacing(0.5)
    }
  }
}));

const Privacy = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <main className={classes.root}>
      <div className={clsx(classes.container, commonClasses.containerWidth)}>
        <Typography className={classes.title}>
          Privacy Policy
        </Typography>
        <Typography className={classes.description}>
          Experiwear Inc. built the Experiwear app as a Free app. This SERVICE
          is provided by Experiwear Inc. at no cost and is intended for use as is.
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service. If you choose to use our Service, then you
          agree to the collection and use of information in relation to this policy.
          The Personal Information that we collect is used for providing and improving
          the Service. We will not use or share your information with anyone except as
          described in this Privacy Policy. The terms used in this Privacy Policy have
          the same meanings as in our Terms and Conditions, which is accessible at
          Experiwear unless otherwise defined in this Privacy Policy.
        </Typography>

        <Typography className={classes.title}>
          Information Collection and Use
        </Typography>
        <Typography className={classes.description}>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information, including
          but not limited to MAC address, location, survey responses. The information
          that we request will be retained by us and used as described in this
          privacy policy. The app does use third party services that may collect
          information used to identify you. Link to privacy policy of third party
          service providers used by the app{' '}
          <a
            aria-label={SOCIALS.GOOGLE_PLAY_SERVICES.LABEL}
            href={SOCIALS.GOOGLE_PLAY_SERVICES.HREF}
            target='_blank'
            rel='noreferrer'
            className={classes.link}
          >
            Google Play Services
          </a>.
        </Typography>

        <Typography className={classes.title}>
          Log Data
        </Typography>
        <Typography className={classes.description}>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third party
          products) on your phone called Log Data. This Log Data may include information
          such as your device Internet Protocol ("IP") address, device name, operating
          system version, the configuration of the app when utilizing our Service,
          the time and date of your use of the Service, and other statistics.
        </Typography>

        <Typography className={classes.title}>
          Cookies
        </Typography>
        <Typography className={classes.description}>
          Cookies are files with a small amount of data that are commonly used as
          anonymous unique identifiers. These are sent to your browser from the
          websites that you visit and are stored on your device's internal memory.
          This Service does not use these "cookies" explicitly. However, the app
          may use third party code and libraries that use "cookies" to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </Typography>

        <Typography className={classes.title}>
          Service Providers
        </Typography>
        <Typography className={classes.description}>
          We may employ third-party companies and individuals due to the following
          reasons:
        </Typography>
        <ul className={classes.list}>
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <Typography className={classes.description}>
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the tasks
          assigned to them on our behalf. However, they are obligated not to disclose
          or use the information for any other purpose.
        </Typography>

        <Typography className={classes.title}>
          Security
        </Typography>
        <Typography className={classes.description}>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we cannot
          guarantee its absolute security.
        </Typography>

        <Typography className={classes.title}>
          Links to Other Sites
        </Typography>
        <Typography className={classes.description}>
          This Service may contain links to other sites. If you click on a third-party
          link, you will be directed to that site. Note that these external sites
          are not operated by us. Therefore, we strongly advise you to review the
          Privacy Policy of these websites. We have no control over and assume no
          responsibility for the content, privacy policies, or practices of any
          third-party sites or services.
        </Typography>

        <Typography className={classes.title}>
          Children's Privacy
        </Typography>
        <Typography className={classes.description}>
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13 years of age. In the case we discover that a child under
          13 has provided us with personal information, we immediately delete
          this from our servers. If you are a parent or guardian and you are
          aware that your child has provided us with personal information,
          please contact us so that we will be able to do necessary actions.
        </Typography>

        <Typography className={classes.title}>
          Changes to This Privacy Policy
        </Typography>
        <Typography className={classes.description}>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this page.
          <br />
          This policy is effective as of 2021-03-29
        </Typography>

        <Typography className={classes.title}>
          Contact Us
        </Typography>
        <Typography className={classes.description}>
          If you have any questions or suggestions about our Privacy Policy,
          do not hesitate to contact us at{' '}
          <a href={`mailto:yonatan.lvovich@experiwear.com`} className={classes.link}>
            yonatan.lvovich@experiwear.com
          </a>.
        </Typography>
      </div>
    </main>
  )
};

export default memo(Privacy);
