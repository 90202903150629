import React from 'react';

import Layout from '@Layout';
import Privacy from '@containers/Privacy';

const PrivacyPage = () => (
  <Layout>
    <Privacy />
  </Layout>
);

export default PrivacyPage;
